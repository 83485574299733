<template>
  <div>
    <v-container grid-list-md>
      <vue-clip
        ref="vue_clip"
        :options="options"
        :on-init="init"
        :on-added-file="addedFile"
        :on-sending="sending"
        :on-complete="complete"
      >
        <template slot="clip-uploader-action" slot-scope="params">
          <v-card color="#0083FF" height="36" class="my-attach">
            <v-btn v-if="adding()" color="#FFF" @click="cancel()" text right class="upload-cancel">
              <v-icon>mdi-close</v-icon>
              <div class="attach_text">Cancelar</div>
            </v-btn>
            <v-btn v-else-if="attachadd" color="#FFF" :class="{'is-dragging': params.dragging}" text class="dz-message upload-action">
              <v-icon>mdi-paperclip</v-icon>
              <div class="attach_text">Clique para incluir anexos ou arraste-os aqui</div>
            </v-btn>
            <h3 v-else color="#FFF" class="text-center pt-1">Anexos</h3>
          </v-card>
          <v-progress-linear
            :indeterminate="true"
            :active="starting > 0"
            class="starting"
            color="red"
            height="4"
          ></v-progress-linear>

          <span style="color: red;">Imagens poderão ser redimensionadas para 1280 x 720 px</span>
        </template>

        <template slot="clip-uploader-body" slot-scope="props">
          <v-layout wrap>
            <v-flex v-for="(file, index) in props.files" :key="index"
              v-show="file.status !== 'removed'" xs12 md2
            >
              <v-card>
                <img v-if="isImage(file)" :src="fileURI(file)" width="100%" @click="showImages(file)">
                <!-- NAO USE ESTE <v-img v-else :src="file.dataUrl"></v-img>-->
                <!-- <v-layout v-else justify-center row fill-height>
                  <v-btn text :loading="file.loading" @click="download(file)">
                    <v-icon>mdi-file-download</v-icon>
                  </v-btn>
                </v-layout> -->
                <v-card-title primary-title class="filename">
                  {{ file.name }}
                </v-card-title>
                <v-container class="pb-1">
                  <v-progress-linear v-if="file.status==='added'" :value="file.progress"/>
                  <v-row v-else class>
                    <div v-if="file.status==='error'" class="error mx-1 mt-1 pa-1">
                      {{ file.errorMessage }}
                    </div>
                    <div v-else>
                      <div class="size mx-1">{{ file.size }} bytes</div>
                      <v-spacer />
                      <v-btn icon small class="delete" @click="download(file)">
                        <v-icon icon small>mdi-file-download</v-icon>
                      </v-btn>
                      <v-btn icon small class="delete" @click="copyClipboard(file)">
                        <v-icon icon small>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn v-if="attachdel" icon small class="delete" @click="removeFile(file, index)">
                        <v-icon icon small>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-row>
                </v-container>
              </v-card>
            </v-flex>
          </v-layout>
        </template>
      </vue-clip>
    </v-container>

    <v-dialog v-model="dialog" >
      <v-container class="pa-5 py-10">
        <v-carousel v-if="carousel" :value="imageid" height="100%">
          <v-carousel-item v-for="(item, i) in images" :src="item.src" :key="i">
            <v-responsive dark>
              <v-container fill-height>
                <v-layout align-center>
                  <v-flex>
                    <h2 class="text-white black-border"> {{ item.filename }} </h2>
                    <span class="text-white black-border"> {{ item.size }} bytes</span>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-btn fab style="opacity: 0.3;" @click="download(item.file)">
                    <v-icon large>mdi-file-download</v-icon>
                  </v-btn>
                </v-layout>
              </v-container>
            </v-responsive>
          </v-carousel-item>
        </v-carousel>
      </v-container>
    </v-dialog>

  </div>
</template>

<script>
  /*
    https://github.com/thetutlage/vue-clip
    https://www.dropzonejs.com
  */
  import ajax from '@/js/base/ajax'
  import { saveAs } from 'file-saver'
  import store from '@/store'

  // function errorHandler (e) {
  //   console.log(e)
  // }

  const b64toBlob = (b64Data, sliceSize = 512) => {
    let pos = b64Data.indexOf(',')
    let contentType = b64Data.substr(0, pos)
    b64Data = b64Data.substr(pos + 1)
    const byteCharacters = atob(b64Data)
    const byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    const blob = new Blob(byteArrays, {type: contentType})
    return blob
  }  

  export default {
    props: {
      name: { type: String, default: 'main' },
      attachview: { type: Boolean, default: true },
      attachadd: { type: Boolean, default: true },
      attachdel: { type: Boolean, default: true },
      editedItem: { default: {} },
      loadImages: { type: Boolean, default: true },
      main: { default: null }
    },

    data () {
      return {
        options: {
          url: this.$store.state.serverURI + 'attach.php',
          method: 'POST',
          paramName: 'file',
          // headers: {
          //   'Authorization': this.$store.state.token,
          //   'Db': this.$store.state.instance,
          // },
          maxFilesize: { limit: 25, message: 'Arquivo excede 25 Mbytes' }
          // maxFiles: { limit: 20, message: 'Limite de 20 arquivos excedido' }
          // acceptedFiles: 'image/*,application/pdf,.txt,.doc',
          // thumbnailWidth: 120,
          // thumbnailHeight: null
          // resizeWidth: 50,   // está fazendo resize localmente
          // resizeHieght: null // mas envia pro servidor sem resize
        },
        files: [],
        starting: 0,
        dialog: false,
        images: [],
        imageid: 0,
        carousel: false
      }
    },

    watch: {
      editedItem (val, old) {
        if (val !== old)
          this.reset()
      }
    },

    computed: {
      parentId () {
        return this.main ? this.main.editedItem.id : null
      }
    },

    methods: {
      showImages (select) {
        this.imageid = 0
        this.carousel = false
        this.images.splice(0, this.images.length)
        for (var file of this.files) {
          if (file.dataUrl || this.isImage(file)) {
            this.images.splice(this.images.length, 0, {
              'src': file.dataUrl || this.fileURI(file),
              'filename': file.name,
              'size': file.size,
              'file': file
            })
            if (file === select) this.imageid = this.images.length - 1
          }
        }
        this.$nextTick(() => (this.carousel = true))
        this.dialog = true
      },

      init (uploader) {
        if (this.attachview) {
          this.starting += 1
          this.$store.commit('INC_AJAX')
          var that = this
          ajax.request(
            'attach.php',
            { name: this.name,
              parentId: this.parentId,
              service: 'list',
              id: this.editedItem.id,
              signature: this.editedItem.__signature__
            },
            response => {
              for (let attach of response.data.attachments) {
                let mockFile = {
                  status: 'success',
                  name: attach.nomeOriginal,
                  progress: 100,
                  size: attach.tamanho,
                  bytesSent: 0,
                  total: attach.tamanho,
                  upload: {
                    bytesSent: 0,
                    total: attach.tamanho,
                    image_route: attach.arquivo
                  }
                }
                uploader.addedFile(mockFile)
                let file = that.files[that.files.length - 1]
                file.customAttributes.id = attach.id
                file.customAttributes.signature = attach.signature
                // let arquivo = attach.arquivo.split('/')
                // file.customAttributes.filename = arquivo[arquivo.length - 1]
                file.customAttributes.filename = attach.arquivo
                file.customAttributes.filetype = attach.tipo
                // file.download = !that.isImage(file)
              }
            },
            null,
            () => {
              that.$store.commit('DEC_AJAX')
              that.starting -= 1
              // if (that.loadImages) that.inCache()
            }
          )
        }
      },

      isImage (file) {
        return (file.customAttributes.filetype || '').startsWith('image')
      },

      fileURI (file) {
        let name = this.$store.state.serverURI + file.customAttributes.filename
        return name
      },

      // inCache () {
      //   // lista de arquivos temporarios
      //   var that = this
      //   function onInitFs (fs) {
      //     var dirReader = fs.root.createReader()
      //     dirReader.readEntries(function (results) {
      //       that.autoLoad(results)
      //     }, errorHandler)
      //   }
      //   window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem
      //   window.requestFileSystem(window.TEMPORARY, 2 * 1024 * 1024 /* 2 Mbytes */, onInitFs, errorHandler)
      // },
      // autoLoad (dirList) {
      //   var dir = dirList
      //   var that = this
      //   this.files.forEach(file => {
      //     if (file.customAttributes.filetype.startsWith('image')) {
      //       let found = false
      //       for (let index in dir) {
      //         if (file.customAttributes.filename === dir[index].name) {
      //           found = true
      //           break
      //         }
      //       }
      //       if (found) {
      //         // carrega image do cache
      //         file.download = false
      //         that.starting += 1
      //         window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem
      //         window.requestFileSystem(window.TEMPORARY, 2 * 1024 * 1024 /* 2 Mbytes */, function (fs) {
      //           fs.root.getFile(file.customAttributes.filename, {}, function (fileEntry) {
      //             fileEntry.file(function (f) {
      //               let reader = new FileReader()
      //               reader.onloadend = function (e) {
      //                 file.dataUrl = this.result
      //                 that.starting -= 1
      //               }
      //               reader.readAsText(f)
      //             }, errorHandler)
      //           }, errorHandler)
      //         }, errorHandler)
      //       } else {
      //         this.download(file)
      //       }
      //     }
      //   })
      // },

      download (file) {
        this.$store.commit('INC_AJAX')
        file.loading = true
        var that = this
        this.starting += 1
        ajax.request(
          'attach.php',
          { name: this.name,
            parentId: this.parentId,
            service: 'download',
            id: this.editedItem.id,
            signature: this.editedItem.__signature__,
            attachID: file.customAttributes.id
          },
          response => {
            let base64 = response.data.base64
            // if (base64.startsWith('data:image')) {
            //   // salva arquivo de imagem em cache (pasta temporario)
            //   file.dataUrl = base64
            //   window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem
            //   window.requestFileSystem(window.TEMPORARY, 2 * 1024 * 1024 /* 2 Mbytes */, function (fs) {
            //     fs.root.getFile(file.customAttributes.filename, { create: true, exclusive: false }, function (fileEntry) {
            //       fileEntry.createWriter(function (fileWriter) {
            //         fileWriter.write(new Blob([file.dataUrl], {type: 'text/plain'}))
            //       })
            //     }, errorHandler)
            //   }, errorHandler)
            // } else {
            //   // baixa arquivo que nao é imagem na pasta de download
            //   let blob = b64toBlob(base64)
            //   let blobUrl = URL.createObjectURL(blob)
            //   saveAs(blobUrl, response.data.nomeOriginal)
            // }
            let blob = b64toBlob(base64)
            let blobUrl = URL.createObjectURL(blob)
            saveAs(blobUrl, response.data.nomeOriginal)
          },
          null,
          () => {
            // file.download = false
            file.loading = false
            that.starting -= 1
            that.$store.commit('DEC_AJAX')
          }
        )
      },

      // saveImg (file) {
      //   let blob = b64toBlob(file.src)
      //   let blobUrl = URL.createObjectURL(blob)
      //   saveAs(blobUrl, file.filename)
      // },

      addedFile (file) {
        this.files.push(file)
        // this.$set(file, 'download', false)
        this.$set(file, 'loading', false)
      },

      async removeFile (file, index) {
        if (file.customAttributes.id > 0) {
          const res = await this.$confirm(
            '<BR>Você tem certeza que deseja excluir este arquivo?',
            { title: 'Excluir' }
          )
          if (res) {
            var that = this
            that.starting += 1
            ajax.request(
              'attach.php',
              { name: this.name,
                parentId: this.parentId,
                service: 'delete',
                id: file.customAttributes.id,
                signature: file.customAttributes.signature
              },
              response => {
                if (response.data.deleted) {
                  that.$refs.vue_clip.removeFile(file)
                  that.files.splice(index, 1)
                }
              },
              null,
              () => {
                that.starting -= 1
              }
            )
          }
        } else {
          this.$refs.vue_clip.removeFile(file)
          this.files.splice(index, 1)
        }
      },

      async copyClipboard (file) {
        let uri = this.fileURI(file)
        let x = uri.indexOf('//')
        if (x >= 0)
          uri = uri.substr(x+2)
        await navigator.clipboard.writeText(uri)
        store.commit('ADD_ALERT', 'URL copiada')
      },

      sending (file, xhr, formData) {
        formData.append('Authorization', this.$store.state.token)
        formData.append('Instance', this.$store.state.instance)
        formData.append('clientVersion', this.$store.state.clientVersion)
        formData.append('name', this.name)
        if (this.parentId)
          formData.append('parentId', this.parentId)
        formData.append('service', 'add')
        formData.append('id', this.editedItem.id)
        formData.append('signature', this.editedItem.__signature__)
        this.starting += 1
        this.$store.commit('INC_AJAX')
      },

      complete (file, status, xhr) {
        this.starting -= 1
        this.$store.commit('DEC_AJAX')
        if (status === 'success' && xhr.response) {
          let data = JSON.parse(xhr.response)
          file.addAttribute('id', data.id)
          file.addAttribute('signature', data.signature)
          file.addAttribute('filename', data.arquivo)
          file.addAttribute('filetype', data.tipo)
        }
      },

      adding () {
        let count = 0
        this.files.forEach(file => {
          if (file.status === 'added') count += 1
        })
        return count > 0
      },

      cancel () {
        for (var index in this.files) {
          let file = this.files[index]
          if (file.status === 'added') {
            this.$refs.vue_clip.removeFile(file)
            this.files.splice(index, 1)
          }
        }
      },

      reset () {
        this.files.splice(0, this.files.length)
        this.$refs.vue_clip.files.splice(0, this.$refs.vue_clip.files.length)
        this.$refs.vue_clip.onInit(this.$refs.vue_clip)
      }
    }
  }
</script>

<style scoped>
  .black-border {
    text-shadow: 2px 2px black;
  }
  .v-toolbar__title, .dz-message {
    width: 100%;
  }
  .upload-action.is-dragging {
    background: gray;
  }
  .upload-cancel {
    float: right;
  }
  .attach_text {
    font-size: smaller;
    overflow: hidden;
    text-overflow: ellipsis;    
  }
  .v-card__title {
    padding: 5px;
  }
  .v-card__text {
    padding: 0 5px 5px 5px;
  }
  .filename {
    text-align: center;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 5px 0 5px;
    line-height: 16px;
  }
  .error {
    text-align: center;
    font-size: smaller;
    color: white;
    background-color: red;
  }
  .size {
    text-align: left;
    font-size: 12px;
  }
  .delete {
    bottom: 5px !important;
  }
  .starting {
    margin: 0;
  }
  .text-white {
    color: white;
  }
  .my-attach {
    max-width: 500px;
    margin-bottom: 6px;
  }
</style>

<style>
  .v-btn__content {
    width: 100%;
  }
</style>

